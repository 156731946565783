<template>
  <div class="login-container">
    <div class="login-box">
      <img src="/logo.png" alt="Logo" class="logo">
      <h1>Sign in</h1>
      <form @submit.prevent="login">
        <div class="input-group">
          <label for="email">Email address</label>
          <input type="email" id="email" v-model="email" required>
        </div>
        <div class="input-group">
          <label for="password">Password</label>
          <input type="password" id="password" v-model="password" required>
        </div>
        <button type="submit" class="btn">Sign in</button>
      </form>
      <div class="register-link">
        Don't have an account yet? 
        <button @click="showRegisterModal" class="register-btn">Register!</button>
      </div>
    </div>
    <register-user-modal v-if="isRegisterModalVisible" @close="isRegisterModalVisible = false" />
  </div>
</template>

<script>
import apiService from '@/apiService';
import RegisterUserModal from '@/components/RegisterUserModal.vue';

export default {
  name: 'AppLogin',
  components: {
    RegisterUserModal
  },
  data() {
    return {
      email: '',
      password: '',
      isRegisterModalVisible: false
    };
  },
  methods: {
    async login() {
      try {
        const response = await apiService.post('/api/token/', {
          email: this.email,
          password: this.password
        });
        const { access, refresh, email, first_name, last_name, role } = response.data;
        localStorage.setItem('access_token', access);
        localStorage.setItem('refresh_token', refresh);
        localStorage.setItem('user', JSON.stringify({ email, first_name, last_name, role }));
        this.$router.push('/');
      } catch (error) {
        console.error('Login failed', error);
        alert('Login failed. Please check your credentials.');
      }
    },
    showRegisterModal() {
      this.isRegisterModalVisible = true;
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #100e1f;
}

.login-box {
  background-color: #1e1e2f;
  width: 50vh;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

h1 {
  color: #ffffff;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #2a2a3d;
  color: #ffffff;
}

.btn {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #3b3b4e;
  color: #ffffff;
  cursor: pointer;
}

.btn:hover {
  background-color: #4b4b5e;
}

.register-link {
  margin-top: 20px;
  color: #ffffff;
}

.register-link .register-btn {
  background: none;
  border: none;
  color: #1e90ff;
  cursor: pointer;
  text-decoration: none;
}

.register-link .register-btn:hover {
  text-decoration: underline;
}
</style>
