<!-- src/components/NavBar.vue -->
<template>
    <div class="navbar">
      <button @click="watchTutorial" class="btn primary">Watch Tutorial</button>
      <button @click="upgradePlan" class="btn secondary">Upgrade Plan/Top Up Quota</button>
    </div>
  </template>
  
  <script>
  export default {
    name: "NavBar",
    methods: {
      watchTutorial() {
        window.open("https://youtu.be/HmdAin6DCK8", "_blank");
      },
      upgradePlan() {
        window.open("https://api.whatsapp.com/send/?phone=6282220578112&text=Halo%2C+saya+ingin+top+up+atau+upgrade+plan&type=phone_number&app_absent=0", "_blank");
      }
    }
  };
  </script>
  
  <style scoped>
  .navbar {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    background-color: #1e1e2f;
    border-bottom: 1px solid #444;
  }
  
  .btn {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
  }
  
  .btn.primary {
    background-color: #1e90ff;
  }
  
  .btn.primary:hover {
    background-color: #1c86ee;
  }
  
  .btn.secondary {
    background-color: #3b3b4e;
  }
  
  .btn.secondary:hover {
    background-color: #4b4b5e;
  }
  </style>
  