<template>
    <div class="modal-overlay">
      <div class="modal">
        <h2>Create New User</h2>
        <form @submit.prevent="createUser">
          <div class="input-group">
            <label for="username">Username</label>
            <input type="text" id="username" v-model="username" required>
          </div>
          <div class="input-group">
            <label for="first_name">First Name</label>
            <input type="text" id="first_name" v-model="first_name" required>
          </div>
          <div class="input-group">
            <label for="last_name">Last Name</label>
            <input type="text" id="last_name" v-model="last_name" required>
          </div>
          <div class="input-group">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="email" required>
          </div>
          <div class="input-group">
            <label for="password">Password</label>
            <input type="password" id="password" v-model="password" required>
          </div>
          <div class="input-group">
            <label for="role">Role</label>
            <select id="role" v-model="role" required>
              <option value="1">Admin</option>
              <option value="2">Maintainer</option>
            </select>
          </div>
          <button type="submit" class="btn">Create</button>
          <button type="button" class="btn" @click="$emit('close')">Cancel</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import apiService from '@/apiService';
  
  export default {
    name: 'CreateUserModal',
    data() {
      return {
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        role: 1
      };
    },
    methods: {
      async createUser() {
        try {
          await apiService.post('/api/users/', {
            username: this.username,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            role: this.role
          });
          this.$emit('user-created');
          this.$emit('close');
        } catch (error) {
          console.error('Error creating user:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: #1e1e2f;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
  }
  
  h2 {
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  label {
    color: #ffffff;
    display: block;
    margin-bottom: 5px;
  }
  
  input, select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #444;
    background-color: #2a2a3d;
    color: #ffffff;
  }
  
  button {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: none;
    background-color: #3b3b4e;
    color: #ffffff;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #4b4b5e;
  }
  </style>
  