<!-- src/views/HomeView.vue -->
<template>
  <div class="home">
    <AppSidebar />
    <div class="main-content">
      <NavBar />
      <UserDashboard />
    </div>
  </div>
</template>

<script>
import AppSidebar from "@/components/AppSidebar.vue";
import UserDashboard from "@/components/UserDashboard.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "HomeView",
  components: {
    AppSidebar,
    UserDashboard,
    NavBar
  }
};
</script>

<style scoped>
.home {
  display: flex;
  background-color: #100e1f;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>
