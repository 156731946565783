<template>
    <div class="user-management-view">
      <AppSidebar />
      <div class="content">
        <NavBar></NavBar>
        <UserManagement />
      </div>
    </div>
  </template>
  
  <script>
  import AppSidebar from '@/components/AppSidebar.vue';
  import NavBar from '@/components/NavBar.vue';
  import UserManagement from '@/components/UserManagement.vue';
  
  export default {
    name: 'UserManagementView',
    components: {
      AppSidebar,
      UserManagement,
      NavBar
    }
  };
  </script>
  
  <style scoped>
  .user-management-view {
    display: flex;
    background-color: #100e1f;
    min-height: 100vh;
  }
  
  .content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
    color: #ffffff;
  }
  </style>
  