<template>
  <div class="manage-knowledge-view">
    <AppSidebar />
    <div class="content">
      <NavBar></NavBar>
      <button @click="goBack" class="btn back">← Back to Chatbot List</button>
      <h1 v-if="chatbot">Manage Knowledge for Chatbot {{ chatbot.name }}</h1>
      <div v-if="chatbot" class="chatbot-metadata">
        <p><strong>Name:</strong> {{ chatbot.name }}</p>
        <p><strong>Pre-Prompt:</strong> {{ chatbot.pre_prompt }}</p>
      </div>
      <div v-if="chatbot" class="knowledge-list">
        <div v-if="knowledgeItems.length" class="knowledge-grid">
          <div v-for="item in knowledgeItems" :key="item.id" class="knowledge-item">
            <div class="file-icon">📄</div>
            <p>{{ getFileName(item.storage_url) }}</p>
            <button @click="confirmDeleteKnowledge(item.id)" class="btn danger">Delete</button>
          </div>
        </div>
        <div v-else>
          <p>No knowledge files found.</p>
        </div>
        <button @click="showUploadModal" class="add-knowledge-btn">+</button>
        <upload-knowledge-modal
          v-if="isUploadModalVisible"
          :chatbot-id="chatbotId"
          @close="hideUploadModal"
          @knowledge-uploaded="fetchKnowledge"
        ></upload-knowledge-modal>
      </div>
      <div v-if="notification" class="notification" :class="notificationType">
        {{ notification }}
      </div>
    </div>
  </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar.vue';
import NavBar from '@/components/NavBar.vue';
import UploadKnowledgeModal from '@/components/UploadKnowledgeModal.vue';
import apiService from '@/apiService';

export default {
  name: 'ManageKnowledge',
  components: {
    AppSidebar,
    UploadKnowledgeModal,
    NavBar
  },
  data() {
    return {
      chatbotId: this.$route.params.chatbotId,
      chatbot: null,
      knowledgeItems: [],
      isUploadModalVisible: false,
      notification: '',
      notificationType: '' // 'success' or 'error'
    };
  },
  methods: {
    async fetchChatbot() {
      try {
        const response = await apiService.get(`/api/chatbots/${this.chatbotId}/`);
        this.chatbot = response.data;
      } catch (error) {
        console.error('Error fetching chatbot metadata:', error);
        const errorMessage = error.response && error.response.data && error.response.data.detail
          ? error.response.data.detail
          : 'Error fetching chatbot metadata. Please try again.';
        this.showNotification(errorMessage, 'error');
        if (error.response && error.response.status === 404) {
          this.$router.push('/404');
        }
      }
    },
    async fetchKnowledge() {
      try {
        const response = await apiService.get(`/api/knowledge/?chatbotId=${this.chatbotId}`);
        console.log(response)
        this.knowledgeItems = response.data;
      } catch (error) {
        console.error('Error fetching knowledge:', error);
        const errorMessage = error.response && error.response.data && error.response.data.detail
          ? error.response.data.detail
          : 'Error fetching knowledge. Please try again.';
        this.showNotification(errorMessage, 'error');
      }
    },
    getFileName(url) {
      return url.split('/').pop();
    },
    showUploadModal() {
      this.isUploadModalVisible = true;
    },
    hideUploadModal() {
      this.isUploadModalVisible = false;
    },
    goBack() {
      this.$router.push({ name: 'ChatBots' });
    },
    confirmDeleteKnowledge(itemId) {
      if (confirm("Are you sure you want to delete this file?")) {
        this.deleteKnowledge(itemId);
      }
    },
    async deleteKnowledge(itemId) {
      try {
        await apiService.delete(`/api/knowledge/${itemId}/`);
        this.fetchKnowledge();
        this.showNotification('Knowledge file deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting knowledge:', error);
        const errorMessage = error.response && error.response.data && error.response.data.detail
          ? error.response.data.detail
          : 'Error deleting knowledge. Please try again.';
        this.showNotification(errorMessage, 'error');
      }
    },
    showNotification(message, type) {
      this.notification = message;
      this.notificationType = type;
      setTimeout(() => {
        this.notification = '';
      }, 3000);
    }
  },
  created() {
    this.fetchChatbot().then(() => {
      if (this.chatbot) {
        this.fetchKnowledge();
      }
    });
  }
};
</script>

<style scoped>
.manage-knowledge-view {
  display: flex;
  background-color: #100e1f;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  padding: 20px;
  color: #ffffff;
  position: relative;
}

.back {
  background-color: #3b3b4e;
  margin-bottom: 20px;
}

.back:hover {
  background-color: #4b4b5e;
}

.chatbot-metadata {
  margin-bottom: 20px;
}

.knowledge-list {
  margin-top: 20px;
}

.knowledge-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.knowledge-item {
  background-color: #1e1e2f;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  flex: 0 0 200px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.file-icon {
  font-size: 48px;
  margin-bottom: 10px;
  color: #ffffff; /* Ensure the file icon is white */
}

button {
  padding: 10px;
  margin: 10px;
  background-color: #3b3b4e;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #4b4b5e;
}

.btn.primary {
  background-color: #1e90ff;
}

.btn.primary:hover {
  background-color: #1c86ee;
}

.btn.secondary {
  background-color: #3b3b4e;
}

.btn.secondary:hover {
  background-color: #4b4b5e;
}

.btn.danger {
  background-color: #ff4d4d;
}

.btn.danger:hover {
  background-color: #ff1a1a;
}

.add-knowledge-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #1e90ff;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.add-knowledge-btn:hover {
  background-color: #1c86ee;
}

.notification {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
}

.notification.success {
  background-color: #28a745;
}

.notification.error {
  background-color: #dc3545;
}
</style>
