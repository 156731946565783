<template>
  <div class="manage-api-keys-view">
    <AppSidebar />
    <div class="content">
      <NavBar></NavBar>
      <button @click="goBack" class="btn back">← Back to Chatbot List</button>
      <h1>Manage API Keys for Chatbot {{ chatbot.name }}</h1>
      <div class="chatbot-metadata">
        <p><strong>Name:</strong> {{ chatbot.name }}</p>
        <p><strong>Pre-Prompt:</strong> {{ chatbot.pre_prompt }}</p>
      </div>
      <div class="api-key-list">
        <div class="buttons">
          <button @click="showCreateApiKeyModal" class="btn primary">Create New API Key</button>
          <button @click="openDocumentation" class="btn documentation">Open Documentation</button>
        </div>
        <div v-if="apiKeys.length" class="table-container">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>API Key</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="apiKey in apiKeys" :key="apiKey.id">
                <td>{{ apiKey.id }}</td>
                <td>{{ maskApiKey(apiKey.api_key) }}</td>
                <td>
                  <button @click="copyToClipboard(apiKey.api_key)" class="btn secondary">Copy</button>
                  <button @click="confirmDeleteApiKey(apiKey.id)" class="btn danger">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p>No API keys found.</p>
        </div>
        <create-api-key-modal
          v-if="isCreateApiKeyModalVisible"
          :chatbotId="chatbotId"
          @close="hideCreateApiKeyModal"
          @api-key-created="fetchApiKeys"
        ></create-api-key-modal>
      </div>
    </div>
  </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar.vue';
import NavBar from '@/components/NavBar.vue';
import CreateApiKeyModal from '@/components/CreateApiKeyModal.vue';
import apiService from '@/apiService';

export default {
  name: 'ManageApiKeys',
  components: {
    AppSidebar,
    CreateApiKeyModal,
    NavBar
  },
  data() {
    return {
      chatbotId: this.$route.params.chatbotId,
      chatbot: {
        name: '',
        pre_prompt: ''
      },
      apiKeys: [],
      isCreateApiKeyModalVisible: false
    };
  },
  methods: {
    async fetchChatbot() {
      try {
        const response = await apiService.get(`/api/chatbots/${this.chatbotId}/`);
        this.chatbot = response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.$router.push('/404');
        } else {
          console.error('Error fetching chatbot metadata:', error);
        }
      }
    },
    async fetchApiKeys() {
      try {
        const response = await apiService.get('/api/apikeys/', {
          params: { chatbot_id: this.chatbotId }
        });
        this.apiKeys = response.data;
      } catch (error) {
        console.error('Error fetching API keys:', error);
      }
    },
    showCreateApiKeyModal() {
      this.isCreateApiKeyModalVisible = true;
    },
    hideCreateApiKeyModal() {
      this.isCreateApiKeyModalVisible = false;
    },
    maskApiKey(apiKey) {
      return apiKey ? `${apiKey.substring(0, 4)}****${apiKey.substring(apiKey.length - 4)}` : '';
    },
    async copyToClipboard(apiKey) {
      try {
        await navigator.clipboard.writeText(apiKey);
        alert('API key copied to clipboard');
      } catch (error) {
        console.error('Failed to copy API key:', error);
      }
    },
    confirmDeleteApiKey(apiKeyId) {
      if (confirm("Are you sure you want to delete this API key?")) {
        this.deleteApiKey(apiKeyId);
      }
    },
    async deleteApiKey(apiKeyId) {
      try {
        await apiService.delete(`/api/apikeys/${apiKeyId}/`);
        this.fetchApiKeys();
      } catch (error) {
        console.error('Error deleting API key:', error);
      }
    },
    openDocumentation() {
      window.open('https://doc.clickup.com/9018060739/p/h/8cr9by3-778/37ec9e405927402', '_blank');
    },
    goBack() {
      this.$router.push({ name: 'ChatBots' });
    }
  },
  created() {
    this.fetchChatbot();
    this.fetchApiKeys();
  }
};
</script>

<style scoped>
.manage-api-keys-view {
  display: flex;
  background-color: #100e1f;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  position: relative;
}

.back {
  background-color: #3b3b4e;
  margin-bottom: 20px;
}

.back:hover {
  background-color: #4b4b5e;
}

.chatbot-metadata {
  margin-bottom: 20px;
}

.api-key-list {
  margin-top: 20px;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #1e1e2f;
  border-radius: 8px;
  overflow: hidden;
}

th, td {
  padding: 15px;
  text-align: left;
}

th {
  background-color: #2a2a3d;
  color: #ffffff;
}

td {
  background-color: #1e1e2f;
  color: #ffffff;
}

tr:hover td {
  background-color: #2a2a3d;
}

button {
  padding: 10px;
  margin: 10px;
  background-color: #3b3b4e;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #4b4b5e;
}

.btn.primary {
  background-color: #1e90ff;
}

.btn.primary:hover {
  background-color: #1c86ee;
}

.btn.secondary {
  background-color: #3b3b4e;
}

.btn.secondary:hover {
  background-color: #4b4b5e;
}

.btn.danger {
  background-color: #ff4d4d;
}

.btn.danger:hover {
  background-color: #ff1a1a;
}

.btn.documentation {
  background-color: #ffaa00;
  color: #ffffff;
}

.btn.documentation:hover {
  background-color: #ff9900;
}
</style>
