<template>
  <div class="modal-overlay">
    <div class="modal">
      <h2>Telegram Integration</h2>
      <button class="doc-button" @click="goToDocumentation">Documentation</button>
      <form @submit.prevent="saveTelebot">
        <div class="input-group">
          <label for="token">Telegram Bot Token</label>
          <input type="text" id="token" v-model="token" required>
        </div>
        <button type="submit" class="btn primary">Save</button>
        <button type="button" class="btn" @click="$emit('close')">Cancel</button>
      </form>
      <div v-if="notification" class="notification" :class="notificationType">
        {{ notification }}
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/apiService';

export default {
  name: 'TelebotModal',
  props: {
    chatbotId: {
      type: String,
      required: true
    },
    existingToken: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      token: this.existingToken,
      notification: '',
      notificationType: '' // 'success' or 'error'
    };
  },
  methods: {
    async saveTelebot() {
      try {
        await apiService.post('/api/telebot/', {
          chatbot: this.chatbotId,
          token: this.token
        });
        this.showNotification('Telegram bot token saved successfully', 'success');
        this.$emit('telebot-saved');
        this.$emit('close');
      } catch (error) {
        console.error('Error saving telebot:', error);
        const errorMessage = error.response && error.response.data && error.response.data.detail
          ? error.response.data.detail
          : 'Error saving telebot. Please try again.';
        this.showNotification(errorMessage, 'error');
      }
    },
    showNotification(message, type) {
      this.notification = message;
      this.notificationType = type;
      setTimeout(() => {
        this.notification = '';
      }, 3000);
    },
    goToDocumentation() {
      window.open('https://doc.clickup.com/9018060739/p/h/8cr9by3-778/37ec9e405927402', '_blank');
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #1e1e2f;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  position: relative;
}

h2 {
  color: #ffffff;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #2a2a3d;
  color: #ffffff;
}

.btn {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: none;
  background-color: #3b3b4e;
  color: #ffffff;
  cursor: pointer;
}

.btn.primary {
  background-color: #1e90ff;
}

.btn.primary:hover {
  background-color: #1c86ee;
}

.notification {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
}

.notification.success {
  background-color: #28a745;
}

.notification.error {
  background-color: #dc3545;
}

.doc-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #1e90ff;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.doc-button:hover {
  background-color: #1c86ee;
}
</style>
