<template>
  <div class="profile-settings-view">
    <AppSidebar />
    <div class="content">
      <NavBar></NavBar>
      <ProfileSettingsForm />
    </div>
  </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar.vue';
import NavBar from '@/components/NavBar.vue';
import ProfileSettingsForm from '@/components/ProfileSettingsForm.vue';

export default {
  name: 'ProfileSettings',
  components: {
    AppSidebar,
    ProfileSettingsForm,
    NavBar
  }
};
</script>

<style scoped>
.profile-settings-view {
  display: flex;
  background-color: #100e1f;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: #ffffff;
}
</style>
