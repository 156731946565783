<template>
  <div class="user-management-view">
    <div class="content">
      <div class="header">
        <h1>User Management</h1>
        <button @click="showCreateUserModal" class="btn primary">Create New User</button>
      </div>
      <div v-if="users.length" class="table-container">
        <table>
          <thead>
            <tr>
              <th>Username</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.username }}</td>
              <td>{{ user.first_name }}</td>
              <td>{{ user.last_name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.role.name }}</td>
              <td>
                <button @click="editUser(user)" :disabled="isCurrentUser(user)" class="btn secondary">Edit</button>
                <button @click="managePlan(user)" :disabled="isCurrentUser(user)" class="btn info">Manage Plan</button>
                <button @click="confirmDeleteUser(user.id)" :disabled="isCurrentUser(user)" class="btn danger">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p>No users found.</p>
      </div>
      <create-user-modal v-if="isCreateUserModalVisible" @close="hideCreateUserModal" @user-created="fetchUsers"></create-user-modal>
      <edit-user-modal v-if="isEditUserModalVisible" :user="selectedUser" @close="hideEditUserModal" @user-edited="fetchUsers"></edit-user-modal>
      <plan-management-card v-if="isPlanManagementVisible" :user="selectedUser" @close="hidePlanManagement" @plan-updated="fetchUsers"></plan-management-card>
    </div>
  </div>
</template>

<script>
import CreateUserModal from '@/components/CreateUserModal.vue';
import EditUserModal from '@/components/EditUserModal.vue';
import PlanManagementCard from '@/components/PlanManagementCard.vue';
import apiService from '@/apiService';

export default {
  name: 'UserManagementView',
  components: {
    CreateUserModal,
    EditUserModal,
    PlanManagementCard
  },
  data() {
    return {
      users: [],
      isCreateUserModalVisible: false,
      isEditUserModalVisible: false,
      isPlanManagementVisible: false,
      selectedUser: null,
      currentUser: JSON.parse(localStorage.getItem('user'))
    };
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await apiService.get('/api/users/');
        this.users = response.data;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    showCreateUserModal() {
      this.isCreateUserModalVisible = true;
    },
    hideCreateUserModal() {
      this.isCreateUserModalVisible = false;
    },
    showEditUserModal(user) {
      this.selectedUser = user;
      this.isEditUserModalVisible = true;
    },
    hideEditUserModal() {
      this.isEditUserModalVisible = false;
      this.selectedUser = null;
    },
    managePlan(user) {
      this.selectedUser = user;
      this.isPlanManagementVisible = true;
    },
    hidePlanManagement() {
      this.isPlanManagementVisible = false;
      this.selectedUser = null;
    },
    editUser(user) {
      if (!this.isCurrentUser(user)) {
        this.showEditUserModal(user);
      } else {
        alert("You cannot edit your own account.");
      }
    },
    confirmDeleteUser(userId) {
      if (!this.isCurrentUser({ id: userId })) {
        if (confirm("Are you sure you want to delete this user?")) {
          this.deleteUser(userId);
        }
      } else {
        alert("You cannot delete your own account.");
      }
    },
    async deleteUser(userId) {
      try {
        await apiService.delete(`/api/users/${userId}/`);
        this.fetchUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    },
    isCurrentUser(user) {
      return this.currentUser && this.currentUser.id === user.id;
    }
  },
  created() {
    this.fetchUsers();
  }
};
</script>

<style scoped>
.user-management-view {
  display: flex;
  background-color: #100e1f;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  padding: 20px;
  color: #ffffff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  margin: 0;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #1e1e2f;
  border-radius: 8px;
  overflow: hidden;
}

th, td {
  padding: 15px;
  text-align: left;
}

th {
  background-color: #2a2a3d;
  color: #ffffff;
}

td {
  background-color: #1e1e2f;
  color: #ffffff;
}

tr:hover td {
  background-color: #2a2a3d;
}

.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #ffffff;
  font-size: 14px;
}

.btn.primary {
  background-color: #1e90ff;
}

.btn.primary:hover {
  background-color: #1c86ee;
}

.btn.secondary {
  background-color: #3b3b4e;
}

.btn.secondary:hover {
  background-color: #4b4b5e;
}

.btn.info {
  background-color: #17a2b8;
}

.btn.info:hover {
  background-color: #138496;
}

.btn.danger {
  background-color: #ff4d4d;
}

.btn.danger:hover {
  background-color: #ff1a1a;
}

button:disabled {
  background-color: #888;
  cursor: not-allowed;
}
</style>
