<template>
    <div class="modal-overlay">
      <div class="modal">
        <h2>Edit User</h2>
        <form @submit.prevent="editUser">
          <div class="input-group">
            <label for="username">Username</label>
            <input type="text" id="username" v-model="userData.username" required>
          </div>
          <div class="input-group">
            <label for="first_name">First Name</label>
            <input type="text" id="first_name" v-model="userData.first_name" required>
          </div>
          <div class="input-group">
            <label for="last_name">Last Name</label>
            <input type="text" id="last_name" v-model="userData.last_name" required>
          </div>
          <div class="input-group">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="userData.email" required>
          </div>
          <div class="input-group">
            <label for="password">Password</label>
            <input type="password" id="password" v-model="userData.password">
          </div>
          <div class="input-group">
            <label for="role">Role</label>
            <select id="role" v-model="userData.role.id" required>
              <option value="1">Admin</option>
              <option value="2">Maintainer</option>
            </select>
          </div>
          <button type="submit" class="btn">Save Changes</button>
          <button type="button" class="btn" @click="$emit('close')">Cancel</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import apiService from '@/apiService';
  
  export default {
    name: 'EditUserModal',
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        userData: { ...this.user, password: '' }
      };
    },
    methods: {
      async editUser() {
        const userData = {
          username: this.userData.username,
          first_name: this.userData.first_name,
          last_name: this.userData.last_name,
          email: this.userData.email,
          role: this.userData.role.id,
        };
        if (this.userData.password) {
          userData.password = this.userData.password;
        }
        try {
          await apiService.patch(`/api/users/${this.userData.id}/`, userData);
          this.$emit('user-edited');
          this.$emit('close');
        } catch (error) {
          console.error('Error editing user:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: #1e1e2f;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
  }
  
  h2 {
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  label {
    color: #ffffff;
    display: block;
    margin-bottom: 5px;
  }
  
  input, select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #444;
    background-color: #2a2a3d;
    color: #ffffff;
  }
  
  button {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: none;
    background-color: #3b3b4e;
    color: #ffffff;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #4b4b5e;
  }
  </style>
  