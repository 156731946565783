<template>
  <div class="sidebar">
    <img src="/logo.png" alt="Logo" class="logo">
    <ul class="menu">
      <li><router-link to="/">Home</router-link></li>
      <li v-if="isAdmin"><router-link to="/user-management">User Management</router-link></li>
      <li><router-link to="/chatbots">Chatbots</router-link></li>
      <li><router-link to="/settings">Settings</router-link></li>
    </ul>
    <button @click="logout" class="logout-btn">Logout</button>
  </div>
</template>

<script>
export default {
  name: "AppSidebar",
  computed: {
    isAdmin() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user && user.role === 'Admin';
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user');
      localStorage.removeItem('role');
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.sidebar {
  width: 250px;
  background-color: #1e1e2f;
  color: #ffffff;
  height: 95vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

.logo {
  width: 50px;
  margin-bottom: 20px;
}

.menu {
  list-style-type: none;
  padding: 0;
  width: 100%;
  flex-grow: 1; /* Makes the menu take up all available space */
  overflow-y: auto; /* Ensure menu items are scrollable if too many */
}

li {
  margin: 15px 0;
  width: 100%;
}

a {
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 12px 20px;
  border-radius: 5px;
  transition: background 0.3s, transform 0.3s;
  text-align: left;
  font-size: 16px;
}

a:hover {
  background-color: #2a2a3d;
  transform: scale(1.05);
}

a.router-link-active {
  font-weight: bold;
  background-color: #3b3b4e;
}

.logout-btn {
  padding: 12px 20px;
  width: 100%;
  background-color: #ff4d4d;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  text-align: left;
  transition: background 0.3s, transform 0.3s;
  font-size: 16px;
  margin-top: auto; /* Push the logout button to the bottom */
}

.logout-btn:hover {
  background-color: #ff1a1a;
  transform: scale(1.05);
}
</style>
