<template>
  <div class="profile-settings">
    <h1>Profile Settings</h1>
    <form @submit.prevent="updateSettings">
      <div class="input-group">
        <label for="first_name">First Name</label>
        <input type="text" id="first_name" v-model="settings.first_name" required>
      </div>
      <div class="input-group">
        <label for="last_name">Last Name</label>
        <input type="text" id="last_name" v-model="settings.last_name" required>
      </div>
      <div class="input-group">
        <label for="email">Email</label>
        <input type="email" id="email" v-model="settings.email" required>
      </div>
      <div class="input-group">
        <label>
          <input type="checkbox" v-model="updatePassword"> Update Password
        </label>
      </div>
      <div v-if="updatePassword">
        <div class="input-group">
          <label for="old_password">Old Password</label>
          <input type="password" id="old_password" v-model="settings.old_password">
        </div>
        <div class="input-group">
          <label for="password">New Password</label>
          <input type="password" id="password" v-model="settings.password">
        </div>
      </div>
      <button type="submit" class="btn primary">Save Changes</button>
    </form>
  </div>
</template>

<script>
import apiService from '@/apiService';

export default {
  name: 'ProfileSettingsForm',
  data() {
    return {
      settings: {
        first_name: '',
        last_name: '',
        email: '',
        old_password: '',
        password: ''
      },
      updatePassword: false
    };
  },
  methods: {
    async fetchUserInfo() {
      try {
        const response = await apiService.get('/api/user/settings/');
        const user = response.data;
        this.settings.first_name = user.first_name;
        this.settings.last_name = user.last_name;
        this.settings.email = user.email;
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    },
    async updateSettings() {
      const payload = {
        first_name: this.settings.first_name,
        last_name: this.settings.last_name,
        email: this.settings.email
      };
      if (this.updatePassword) {
        payload.old_password = this.settings.old_password;
        payload.password = this.settings.password;
      }
      try {
        await apiService.put('/api/user/settings/', payload);
        alert('Settings updated successfully');
      } catch (error) {
        console.error('Error updating settings:', error);
        alert('Failed to update settings');
      }
    }
  },
  created() {
    this.fetchUserInfo();
  }
};
</script>

<style scoped>
.profile-settings {
  padding: 20px;
  color: #ffffff;
  background-color: #1e1e2f;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin: 20px;
}

h1 {
  margin-bottom: 20px;
  text-align: center;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #2a2a3d;
  color: #ffffff;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.btn {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #1e90ff;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
}

.btn.primary:hover {
  background-color: #1c86ee;
}
</style>
