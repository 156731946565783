<template>
  <div class="chatbot-list">
    <h1>Chatbots</h1>
    <button @click="showCreateChatbotModal" class="btn primary">Create New Chatbot</button>
    <div v-if="chatbots.length" class="table-container">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Pre-Prompt</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="chatbot in chatbots" :key="chatbot.id">
            <td>{{ chatbot.name }}</td>
            <td>{{ chatbot.pre_prompt }}</td>
            <td>
              <button @click="editChatbot(chatbot)" class="btn secondary">Edit</button>
              <button @click="confirmDeleteChatbot(chatbot.id)" class="btn danger">Delete</button>
              <button @click="manageKnowledge(chatbot)" class="btn info">Manage Knowledge</button>
              <button @click="tryChat(chatbot.id)" class="btn chat">Try Chat</button>
              <button @click="manageApiKeys(chatbot.id)" class="btn api-keys">Manage API Keys</button>
              <button @click="integrateTelegram(chatbot)" class="btn telegram">Telegram Integration</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <p>No chatbots found.</p>
    </div>
    <create-chatbot-modal v-if="isCreateChatbotModalVisible" @close="hideCreateChatbotModal" @chatbot-created="fetchChatbots"></create-chatbot-modal>
    <edit-chatbot-modal v-if="isEditChatbotModalVisible" :chatbot="selectedChatbot" @close="hideEditChatbotModal" @chatbot-edited="fetchChatbots"></edit-chatbot-modal>
    <telebot-modal v-if="isTelebotModalVisible" :chatbot-id="selectedChatbotId" @close="hideTelebotModal" @telebot-saved="fetchChatbots"></telebot-modal>
  </div>
</template>

<script>
import apiService from '@/apiService';
import CreateChatbotModal from '@/components/CreateChatbotModal.vue';
import EditChatbotModal from '@/components/EditChatbotModal.vue';
import TelebotModal from '@/components/TelebotModal.vue';

export default {
  name: 'ChatbotList',
  components: {
    CreateChatbotModal,
    EditChatbotModal,
    TelebotModal
  },
  data() {
    return {
      chatbots: [],
      isCreateChatbotModalVisible: false,
      isEditChatbotModalVisible: false,
      isTelebotModalVisible: false,
      selectedChatbot: null,
      selectedChatbotId: null
    };
  },
  methods: {
    async fetchChatbots() {
      try {
        const response = await apiService.get('/api/chatbots/');
        this.chatbots = response.data;
      } catch (error) {
        console.error('Error fetching chatbots:', error);
      }
    },
    showCreateChatbotModal() {
      this.isCreateChatbotModalVisible = true;
    },
    hideCreateChatbotModal() {
      this.isCreateChatbotModalVisible = false;
    },
    showEditChatbotModal(chatbot) {
      this.selectedChatbot = chatbot;
      this.isEditChatbotModalVisible = true;
    },
    hideEditChatbotModal() {
      this.isEditChatbotModalVisible = false;
      this.selectedChatbot = null;
    },
    editChatbot(chatbot) {
      this.showEditChatbotModal(chatbot);
    },
    confirmDeleteChatbot(chatbotId) {
      if (confirm("Are you sure you want to delete this chatbot?")) {
        this.deleteChatbot(chatbotId);
      }
    },
    async deleteChatbot(chatbotId) {
      try {
        await apiService.delete(`/api/chatbots/${chatbotId}/`);
        this.fetchChatbots();
      } catch (error) {
        console.error('Error deleting chatbot:', error);
      }
    },
    manageKnowledge(chatbot) {
      this.$router.push({ name: 'ManageKnowledge', params: { chatbotId: chatbot.id } });
    },
    tryChat(chatbotId) {
      this.$router.push({ name: 'TryChat', params: { chatbotId } });
    },
    manageApiKeys(chatbotId) {
      this.$router.push({ name: 'ManageApiKeys', params: { chatbotId } });
    },
    integrateTelegram(chatbot) {
      this.selectedChatbotId = chatbot.id;
      this.isTelebotModalVisible = true;
    },
    hideTelebotModal() {
      this.isTelebotModalVisible = false;
      this.selectedChatbotId = null;
    }
  },
  created() {
    this.fetchChatbots();
  }
};
</script>

<style scoped>
.chatbot-list {
  padding: 20px;
  color: #ffffff;
}

button {
  padding: 10px;
  margin: 10px;
  background-color: #3b3b4e;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #4b4b5e;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #1e1e2f;
  border-radius: 8px;
  overflow: hidden;
}

th, td {
  padding: 15px;
  text-align: left;
}

th {
  background-color: #2a2a3d;
  color: #ffffff;
}

td {
  background-color: #1e1e2f;
  color: #ffffff;
}

tr:hover td {
  background-color: #2a2a3d;
}

.btn.primary {
  background-color: #1e90ff;
}

.btn.primary:hover {
  background-color: #1c86ee;
}

.btn.secondary {
  background-color: #3b3b4e;
}

.btn.secondary:hover {
  background-color: #4b4b5e;
}

.btn.danger {
  background-color: #ff4d4d;
}

.btn.danger:hover {
  background-color: #ff1a1a;
}

.btn.info {
  background-color: #17a2b8;
}

.btn.info:hover {
  background-color: #138496;
}

.btn.chat {
  background-color: #28a745;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.btn.chat:hover {
  background-color: #218838;
}

.btn.api-keys {
  background-color: #ffc107;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.btn.api-keys:hover {
  background-color: #e0a800;
}

.btn.telegram {
  background-color: #0088cc;
}

.btn.telegram:hover {
  background-color: #007bb5;
}
</style>
