<template>
  <div class="modal-overlay">
    <div class="modal">
      <h2>Upload Knowledge File</h2>
      <form @submit.prevent="uploadKnowledge">
        <div class="input-group" :class="{ disabled: isLoading }">
          <label for="file">File</label>
          <input type="file" id="file" ref="fileInput" :disabled="isLoading" required>
        </div>
        <button type="submit" class="btn primary" :disabled="isLoading">
          <span v-if="isLoading" class="loader"></span>
          <span v-else>Upload</span>
        </button>
        <button type="button" class="btn" @click="$emit('close')" :disabled="isLoading">Cancel</button>
      </form>
      <div v-if="notification" class="notification" :class="notificationType">
        {{ notification }}
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/apiService';

export default {
  name: 'UploadKnowledgeModal',
  props: {
    chatbotId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      notification: '',
      notificationType: '', // 'success' or 'error'
      isLoading: false
    };
  },
  methods: {
    async uploadKnowledge() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append('file', this.$refs.fileInput.files[0]);
      formData.append('chatbot', this.chatbotId);

      try {
        await apiService.post('/api/knowledge/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.showNotification('Knowledge file uploaded successfully', 'success');
        this.$emit('knowledge-uploaded');
        this.$emit('close');
      } catch (error) {
        console.error('Error uploading knowledge:', error);
        const errorMessage = error.response && error.response.data && error.response.data.detail
          ? error.response.data.detail
          : 'Error uploading knowledge. Please try again.';
        this.showNotification(errorMessage, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    showNotification(message, type) {
      this.notification = message;
      this.notificationType = type;
      setTimeout(() => {
        this.notification = '';
      }, 3000);
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #1e1e2f;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  position: relative;
}

h2 {
  color: #ffffff;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-group.disabled {
  opacity: 0.6;
}

label {
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
}

input[type="file"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #2a2a3d;
  color: #ffffff;
}

.btn {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: none;
  background-color: #3b3b4e;
  color: #ffffff;
  cursor: pointer;
}

.btn.primary {
  background-color: #1e90ff;
}

.btn.primary:hover {
  background-color: #1c86ee;
}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #1e90ff; /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.notification {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
}

.notification.success {
  background-color: #28a745;
}

.notification.error {
  background-color: #dc3545;
}
</style>
