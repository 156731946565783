<template>
  <div class="chatbots-view">
    <AppSidebar />
    <div class="content">
      <NavBar></NavBar>
      <ChatbotList />
    </div>
  </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar.vue';
import NavBar from '@/components/NavBar.vue';
import ChatbotList from '@/components/ChatbotList.vue';

export default {
  name: 'ChatBots',
  components: {
    AppSidebar,
    ChatbotList,
    NavBar
  }
};
</script>

<style scoped>
.chatbots-view {
  display: flex;
  background-color: #100e1f;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: #ffffff;
}
</style>
