<template>
  <div class="try-chat-view">
    <AppSidebar />
    <div class="content">
      <button @click="goBack" class="btn back">← Back to Chatbot List</button>
      <h1 v-if="chatbot">Chat with {{ chatbot.name }}</h1>
      <div v-if="chatbot" class="chat-container">
        <div class="chat-history" ref="chatHistory">
          <div v-for="(chat, index) in chatHistory" :key="index" class="chat-message" :class="chat.role">
            <div class="message-content" v-html="chat.content"></div>
          </div>
        </div>
        <div class="chat-input">
          <input type="text" v-model="userMessage" @keyup.enter="sendMessage" placeholder="Type your message...">
          <button @click="sendMessage" class="btn send">Send</button>
        </div>
      </div>
      <div v-if="notification" class="notification" :class="notificationType">
        {{ notification }}
      </div>
    </div>
  </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar.vue';
import apiService from '@/apiService';
import { marked } from 'marked';

export default {
  name: 'TryChat',
  components: {
    AppSidebar
  },
  data() {
    return {
      chatbotId: this.$route.params.chatbotId,
      chatbot: null,
      userMessage: '',
      chatHistory: [],
      notification: '',
      notificationType: '' // 'success' or 'error'
    };
  },
  methods: {
    async fetchChatbot() {
      try {
        const response = await apiService.get(`/api/chatbots/${this.chatbotId}/`);
        this.chatbot = response.data;
      } catch (error) {
        console.error('Error fetching chatbot metadata:', error);
        if (error.response && error.response.status === 404) {
          this.$router.push('/404');
        }
      }
    },
    async sendMessage() {
      if (this.userMessage.trim() === '') return;

      const message = {
        role: 'user',
        content: this.userMessage
      };
      this.chatHistory.push(message);
      this.scrollToBottom();

      try {
        const response = await apiService.post('/api/chat/chat/', {
          chatbot: this.chatbotId,
          input_text: this.userMessage
        });

        const botMessage = {
          role: 'bot',
          content: marked(response.data) // Use marked as a function
        };
        this.chatHistory.push(botMessage);
        this.scrollToBottom();
        this.showNotification('Message sent successfully', 'success');
      } catch (error) {
        console.error('Error sending message:', error);
        const errorMessage = error.response && error.response.data && error.response.data.detail
          ? error.response.data.detail
          : 'Error sending message. Please try again.';
        this.showNotification(errorMessage, 'error');
      } finally {
        this.userMessage = '';
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatHistory = this.$refs.chatHistory;
        if (chatHistory) {
          chatHistory.scrollTop = chatHistory.scrollHeight;
        }
      });
    },
    showNotification(message, type) {
      this.notification = message;
      this.notificationType = type;
      setTimeout(() => {
        this.notification = '';
      }, 3000);
    },
    goBack() {
      this.$router.push({ name: 'ChatBots' });
    }
  },
  created() {
    this.fetchChatbot();
  }
};
</script>

<style scoped>
.try-chat-view {
  display: flex;
  background-color: #100e1f;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  color: #ffffff;
  position: relative;
}

.back {
  background-color: #3b3b4e;
  margin-bottom: 20px;
}

.back:hover {
  background-color: #4b4b5e;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  max-width: 800px;
  margin: 0 auto;
}

.chat-history {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #1e1e2f;
  border-radius: 8px;
  margin-bottom: 20px;
  max-height: calc(100vh - 220px);
}

.chat-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  word-wrap: break-word;
}

.chat-message.user {
  background-color: #1e90ff;
  text-align: right;
}

.chat-message.bot {
  background-color: #3b3b4e;
  text-align: left;
}

.message-content {
  display: inline-block;
  max-width: 70%;
}

.chat-input {
  display: flex;
  align-items: center;
}

input[type="text"] {
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #2a2a3d;
  color: #ffffff;
  margin-right: 10px;
}

button.send {
  padding: 10px;
  background-color: #1e90ff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.send:hover {
  background-color: #1c86ee;
}

.notification {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
}

.notification.success {
  background-color: #28a745;
}

.notification.error {
  background-color: #dc3545;
}
</style>
