<template>
  <div class="modal-overlay">
    <div class="modal">
      <h2>Create New Chatbot</h2>
      <form @submit.prevent="createChatbot">
        <div class="input-group">
          <label for="name">Name</label>
          <input type="text" id="name" v-model="name" required>
        </div>
        <div class="input-group">
          <label for="pre_prompt">Pre-Prompt</label>
          <input type="text" id="pre_prompt" v-model="pre_prompt" required>
          <p class="sample-text">Contoh: kamu adalah chatbot dari perusahaan XXXX yang bertujuan untuk XXX</p>
        </div>
        <button type="submit" class="btn primary">Create</button>
        <button type="button" class="btn" @click="$emit('close')">Cancel</button>
      </form>
    </div>
  </div>
</template>

<script>
import apiService from '@/apiService';

export default {
  name: 'CreateChatbotModal',
  data() {
    return {
      name: '',
      pre_prompt: ''
    };
  },
  methods: {
    async createChatbot() {
      try {
        await apiService.post('/api/chatbots/', {
          name: this.name,
          pre_prompt: this.pre_prompt
        });
        this.$emit('chatbot-created');
        this.$emit('close');
      } catch (error) {
        console.error('Error creating chatbot:', error);
      }
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #1e1e2f;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
}

h2 {
  color: #ffffff;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #2a2a3d;
  color: #ffffff;
}

.sample-text {
  color: #aaaaaa;
  font-size: 12px;
  margin-top: 5px;
}

.btn {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: none;
  background-color: #3b3b4e;
  color: #ffffff;
  cursor: pointer;
}

.btn.primary {
  background-color: #1e90ff;
}

.btn.primary:hover {
  background-color: #1c86ee;
}
</style>
