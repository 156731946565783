<template>
  <div class="dashboard">
    <h1>Hey there, {{ user.first_name }} {{ user.last_name }} 👋</h1>
    <div class="stats">
      <div class="stat">
        <h2>Number of Chatbots</h2>
        <p>{{ chatbotCount }}</p>
      </div>
      <div class="stat">
        <h2>Number of Chats</h2>
        <p>{{ chatCount }}</p>
      </div>
      <div class="stat">
        <h2>Number of Knowledge</h2>
        <p>{{ knowledgeCount }}</p>
      </div>
      <div v-if="isAdmin" class="stat">
        <h2>Number of Users</h2>
        <p>{{ userCount }}</p>
      </div>
    </div>
    <div class="query-fields">
      <label>
        Start Month:
        <input type="month" v-model="startDate">
      </label>
      <label>
        End Month:
        <input type="month" v-model="endDate">
      </label>
      <button @click="fetchData" class="btn primary">Query</button>
    </div>
    <div class="chart-container">
      <h2>Monthly Chat Traffic</h2>
      <line-chart :width="300" :height="90" :chart-data="chatTrafficData" :options="chartOptions" />
    </div>
  </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js'
import apiService from '@/apiService'

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale)
ChartJS.color = "#FFF"
export default {
  name: "UserDashboard",
  computed: {
    isAdmin() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user && user.role === 'Admin';
    }
  },
  components: {
    'line-chart': Line
  },
  data() {
    return {
      user: {},
      chatbotCount: 0,
      chatCount: 0,
      knowledgeCount: 0,
      userCount: 0,
      startDate: '',
      endDate: '',
      chatTrafficData: {
        labels: [],
        datasets: [
          {
            label: 'Chats',
            backgroundColor: '#42b883',
            borderColor: '#42b883',
            color:'#FFF',
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              color: 'white'
            }
          },
          y: {
            ticks: {
              color: 'white'
            }
          }
        }
      }
    };
  },
  methods: {
    async fetchData() {
      try {
        const params = {}
        if (this.startDate) params.start_date = this.startDate
        if (this.endDate) params.end_date = this.endDate

        const response = await apiService.get('/api/home/', { params })
        const data = response.data
        this.user = JSON.parse(localStorage.getItem('user'))
        this.chatbotCount = data.chatbot_count
        this.chatCount = data.chat_count
        this.knowledgeCount = data.knowledge_count
        this.userCount = data.user_count
        this.isAdmin = this.user.role.name === 'Admin'

        this.chatTrafficData.labels = data.monthly_chat_traffic.map(item => item.date)
        this.chatTrafficData.datasets[0].data = data.monthly_chat_traffic.map(item => item.count)
      } catch (error) {
        console.error('Error fetching dashboard data:', error)
      }
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>
.dashboard {
  padding: 20px;
  color: #ffffff;
  flex-grow: 1;
  background-color: #100e1f;
  min-height: 100vh; /* Ensure the background covers the full viewport height */
  display: flex;
  flex-direction: column;
}

h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

.stats {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.stat {
  background-color: #2a2a3d;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  flex: 1;
  margin: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
  height: 150px; /* Make the card rectangular */
}

.stat:hover {
  transform: translateY(-5px);
}

.stat h2 {
  margin-bottom: 10px;
  font-size: 18px;
}

.stat p {
  font-size: 24px;
  margin: 0;
  font-weight: bold;
}

.query-fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.query-fields label {
  color: #ffffff;
  margin-right: 20px;
}

.query-fields input {
  margin-left: 10px;
}

.chart-container {
  margin-top: 40px;
  height: 100%; /* Adjust chart height */
  width: 100%; /* Ensure chart takes full width */
}

.chart-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.btn.primary {
  background-color: #1e90ff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.btn.primary:hover {
  background-color: #1c86ee;
}
</style>
