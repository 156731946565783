<template>
    <div class="modal-overlay">
      <div class="modal">
        <h2>Manage Plan for {{ user.username }}</h2>
        <form @submit.prevent="savePlan">
          <div class="input-group">
            <label for="start_date">Start Date</label>
            <input type="date" id="start_date" v-model="plan.start_date" required>
          </div>
          <div class="input-group">
            <label for="active_until">Active Until</label>
            <input type="date" id="active_until" v-model="plan.active_until" required>
          </div>
          <div class="input-group">
            <label for="chat_limit">Chat Limit</label>
            <input type="number" id="chat_limit" v-model="plan.chat_limit" required>
          </div>
          <div class="input-group">
            <label for="knowledge_limit">Knowledge Limit</label>
            <input type="number" id="knowledge_limit" v-model="plan.knowledge_limit" required>
          </div>
          <button type="submit" class="btn primary">{{ isPlanEmpty ? 'Create Plan' : 'Update Plan' }}</button>
          <button v-if="!isPlanEmpty" type="button" class="btn danger" @click="deletePlan">Delete Plan</button>
          <button type="button" class="btn secondary" @click="$emit('close')">Cancel</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import apiService from '@/apiService';

  export default {
    name: 'PlanManagementCard',
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        plan: {
          start_date: '',
          active_until: '',
          chat_limit: '',
          knowledge_limit: ''
        },
        isPlanEmpty: true
      };
    },
    methods: {
      async fetchPlan() {
        try {
          const response = await apiService.get(`/api/plan/`, { params: { user: this.user.id } });
          if (response.data.length) {
            this.plan = response.data[0];
            this.isPlanEmpty = false;
          } else {
            this.isPlanEmpty = true;
          }
        } catch (error) {
          console.error('Error fetching plan:', error);
        }
      },
      async savePlan() {
        try {
          if (this.isPlanEmpty) {
            await apiService.post('/api/plan/', { ...this.plan, user: this.user.id });
          } else {
            await apiService.patch(`/api/plan/${this.plan.id}/`, this.plan);
          }
          this.$emit('plan-updated');
          this.$emit('close');
        } catch (error) {
          console.error('Error saving plan:', error);
        }
      },
        async deletePlan() {
        if (confirm('Are you sure you want to delete this plan?')) {
          try {
            await apiService.delete(`/api/plan/${this.plan.id}/`);
            this.$emit('plan-updated');
            this.$emit('close');
          } catch (error) {
            console.error('Error deleting plan:', error);
          }
        }
      }
    },
    created() {
      this.fetchPlan();
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background-color: #1e1e2f;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
  }

  h2 {
    color: #ffffff;
    margin-bottom: 20px;
  }

  .input-group {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #444;
    background-color: #2a2a3d;
    color: #ffffff;
  }

  .btn {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: none;
    background-color: #3b3b4e;
    color: #ffffff;
    cursor: pointer;
  }

  .btn.primary {
    background-color: #1e90ff;
  }

  .btn.primary:hover {
    background-color: #1c86ee;
  }

  .btn.secondary {
    background-color: #3b3b4e;
  }

  .btn.secondary:hover {
    background-color: #4b4b5e;
  }

  .btn.danger {
    background-color: #ff4d4d;
  }

  .btn.danger:hover {
    background-color: #ff3333;
  }
  </style>