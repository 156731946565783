import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import AppLogin from '@/views/AppLogin.vue';
import UserManagementView from '@/views/UserManagement.vue';
import ProfileSettings from '@/views/ProfileSettings.vue';
import ChatBots from '@/views/ChatBots.vue';
import ManageKnowledge from '@/views/ManageKnowledge.vue';
import TryChat from '@/views/TryChat.vue';
import ManageApiKeys from '@/views/ManageApiKeys.vue'; // Import ManageApiKeys view

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'AppLogin',
    component: AppLogin
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    component: UserManagementView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/chatbots',
    name: 'ChatBots',
    component: ChatBots,
    meta: { requiresAuth: true }
  },
  {
    path: '/api-integration',
    name: 'ApiIntegration',
    component: () => import('@/views/ApiIntegration.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'ProfileSettings',
    component: ProfileSettings,
    meta: { requiresAuth: true }
  },
  {
    path: '/manage-knowledge/:chatbotId',
    name: 'ManageKnowledge',
    component: ManageKnowledge,
    meta: { requiresAuth: true }
  },
  {
    path: '/try-chat/:chatbotId',
    name: 'TryChat',
    component: TryChat,
    meta: { requiresAuth: true }
  },
  {
    path: '/manage-apikeys/:chatbotId',
    name: 'ManageApiKeys',
    component: ManageApiKeys,
    meta: { requiresAuth: true }
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  const isAuthenticated = localStorage.getItem('access_token');
  const user = JSON.parse(localStorage.getItem('user'));

  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (requiresAdmin && (!user || user.role !== 'Admin')) {
    next('/404');
  } else {
    next();
  }
});

export default router;
